@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #121214;
  color: white;
  scroll-behavior: smooth;
}
button{
  cursor: pointer;
}
a {
  color: inherit;
  text-decoration: none;
}



